import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { CustomerQuote } from "../components/site";
import { Container, Wrapper, Box } from "../components/util";
import { SignupForm } from "../components/forms";
import Layout from "../components/layout";
import Seo from "../components/seo";

import DroppahLogo from "../images/logos/Droppah/Droppah_Single.svg";
import FromFlexiTime from "../images/logos/FlexiTime/FromFlexiTime_Horiz_White.svg";
import PlayLeft from "../images/graphics/rebrand/Play_Left.svg";
import PlayRight from "../images/graphics/rebrand/Play_Right.svg";

const FullContainer = styled(Container)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    overflow: unset;
  }

  img.graphic {
    position: absolute;
    width: 30%;
    top: 50%;
    transform: translateY(-50%);

    &.-left {
      left: -60px;
    }

    &.-right {
      right: -60px;
    }
  }
`;

const FormWrapper = styled(Wrapper)`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    align-self: center;
  }
`;

const FormBox = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .logo {
    height: 60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    max-width: 100%;

    .logo {
      height: 40px;
    }
  }
`;

const Signup = (props) => (
  <Layout fullPage>
    <Seo
      title="Create Account | Free for up to 10 Employees | Droppah"
      description="Discover Droppah, staff scheduling, time tracking & attendance software for your business. Let's do it."
      pathname={props.location.pathname}
    />
    <FullContainer>
      <img className="graphic -left hideOnMobile" src={PlayLeft} />
      <img className="graphic -right hideOnMobile" src={PlayRight} />
      <FormWrapper stackGap={50}>
        <FormBox className="-center" stackGap={50}>
          <Link to="/" className="link-floating -center">
            <img
              className="logo"
              src={DroppahLogo}
              alt="Droppah | Rosters, Time Tracking & Attendance Software"
            />
          </Link>
          <Box size={100} stackGap={40}>
            <Box className="-textCenter">
              <h1>Get Started</h1>
              <p css={{ color: "#999" }}>
                Free for up to 10 Employees · Easy Set Up · No Credit Card
                Required
              </p>
            </Box>
            <div className="-center" css={{ maxWidth: "500px" }}>
              <SignupForm center submitText="Create Account" candidateSignup={false} />
            </div>
          </Box>
          <a
            className="link-floating -center"
            href="https://www.flexitime.works"
            target="_blank"
          >
            <img
              src={FromFlexiTime}
              alt="FlexiTime | Workforce Management for Modern Employers"
              height="18"
            />
          </a>
        </FormBox>
      </FormWrapper>
    </FullContainer>
  </Layout>
);

export default Signup;
